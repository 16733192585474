export interface StockItem {
  category: string;
  variations: StockItemVariation[];
  price: number;
}

export interface StockItemVariation {
  name: string;
  count: number;
}

const sortAlphabetical = (a: { name: string }, b: { name: string }) => {
  return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
}

const kits = {
  category: "Kit",
  variations: [
    { name: "Bird - Pigeon", count: 2 },
    { name: "Bird - Raven", count: 2 },
    { name: "Bird - Chick", count: 2 },
    { name: "Bird - Chicken", count: 2 },
    { name: "Bird - Magpie", count: 2 },
    { name: "Bird - Duck", count: 2 },
    { name: "Bird - Kookaburra", count: 2 },
    { name: "Bird - Cockatoo", count: 2 },
    { name: "Bird - Budgie (G)", count: 2 },
    { name: "Bird - Budgie (B)", count: 2 },
    { name: "Bird - Lorikeet", count: 2 },
    { name: "Bird - Galah", count: 2 },

    { name: "Eevee", count: 10 },
    { name: "Umbreon", count: 3 },
    { name: "Espeon", count: 5 },
    { name: "Flareon", count: 5 },
    { name: "Jolteon", count: 5 },
    { name: "Vaporeon", count: 5 },
    { name: "Leafeon", count: 5 },
    { name: "Glaceon", count: 1 },
    { name: "Sylveon", count: 10 },
    { name: "Pikachu", count: 10 },
    { name: "Bulbasaur", count: 7 },
    { name: "Squirtle", count: 6 },
    { name: "Charmander", count: 7 },
    { name: "Gengar", count: 7 },
    { name: "Fox", count: 7 },
    { name: "Lion", count: 7 },
    // { name: "Bunny", count: 7 },
    { name: "Panda", count: 7 },
  ].sort(sortAlphabetical),
  price: 3000,
};

const magnets = {
  category: "Magnets",
  variations: [
    { name: "Flower", count: 1 },
    { name: "Sunflower", count: 2 },
    { name: "Dumpling", count: 2 },
    { name: "Pretzel", count: 1 }
    ].sort(sortAlphabetical),
  price: 2000,
}

const coasterKit = {
  category: "Coaster Kit",
  variations: [
    { name: "Blue", count: 1 },
    { name: "White", count: 1 },
    { name: "Green", count: 1 },
    { name: "Yellow", count: 1 },
    { name: "Purple", count: 1 },
    { name: "Red", count: 1 },
    { name: "Pink", count: 1 },
    { name: "Grey", count: 1 },
  ].sort(sortAlphabetical),
  price: 2000,
};

const starterKit = {
  category: "Starter Kit",
  variations: [{ name: "Trio", count: 10 }].sort(sortAlphabetical),
  price: 7000,
};

const birds = {
  category: "Birds",
  variations: [
    { name: "Pigeon", count: 3 },
    { name: "Raven", count: 2 },
    { name: "Chick", count: 2 },
    { name: "Chicken", count: 4 },
    { name: "Magpie", count: 2 },
    { name: "Duck", count: 2 },
    { name: "Kookaburra", count: 2 },
    { name: "Cockatoo", count: 2 },
    { name: "Budgie (G)", count: 2 },
    { name: "Budgie (B)", count: 2 },
    { name: "Lorikeet", count: 2 },
    { name: "Galah", count: 2 },
    { name: "Cockatiel", count: 2 },
  ].sort(sortAlphabetical),
  price: 2500,
};

const cats = {
  category: "Cats",
  variations: [
    { name: "Black", count: 1 },
    { name: "Tuxedo", count: 1 },
    { name: "Ginger", count: 1 },
    { name: "Tabby", count: 1 },
    { name: "Puppycat", count: 1 },
    { name: "Grey", count: 1 },
  ].sort(sortAlphabetical),
  price: 2500,
}

const miniPokemon = {
  category: "Mini Pokemon",
  variations: [
    { name: "Eevee", count: 10 },
    { name: "Umbreon", count: 3 },
    { name: "Espeon", count: 5 },
    { name: "Flareon", count: 5 },
    { name: "Jolteon", count: 5 },
    { name: "Vaporeon", count: 5 },
    { name: "Leafeon", count: 5 },
    { name: "Glaceon", count: 1 },
    { name: "Sylveon", count: 10 },
    { name: "Pikachu", count: 10 },
    { name: "Bulbasaur", count: 7 },
    { name: "Squirtle", count: 6 },
    { name: "Charmander", count: 7 },
  ].sort(sortAlphabetical),
  price: 2500,
};

const pokemon = {
  category: "Pokemon",
  variations: [
    { name: "Eevee", count: 10 },
    { name: "Umbreon", count: 3 },
    { name: "Espeon", count: 5 },
    { name: "Flareon", count: 5 },
    { name: "Jolteon", count: 5 },
    { name: "Vaporeon", count: 5 },
    { name: "Leafeon", count: 5 },
    { name: "Glaceon", count: 1 },
    { name: "Sylveon", count: 10 },
    { name: "Pikachu", count: 10 },
    { name: "Bulbasaur", count: 7 },
    { name: "Squirtle", count: 6 },
    { name: "Charmander", count: 7 },
    { name: "Lion", count: 7 },
    { name: "Panda", count: 7 },
    { name: "Fox", count: 0 },
    { name: "Gengar", count: 10 },
  ].sort(sortAlphabetical),
  price: 4500,
};

const clearDolls = {
  category: "Priced to Clear Dolls",
  variations: [
    // { name: "Yuri", count: 1 },
    { name: "Starlord", count: 10 },
    { name: "No-face (small)", count: 10 },
    { name: "Groot", count: 1 },
    { name: "Black Panther", count: 1 },
  ].sort(sortAlphabetical),
  price: 3000,
};

const mediumDolls = {
  category: "Medium Dolls",
  variations: [
    { name: "Luffy", count: 1 },
    { name: "Black Panther", count: 10 },
  ].sort(sortAlphabetical),
  price: 6000,
};

const smallFluffy = {
  category: "Med Fluffy",
  variations: [
    // { name: "Bulbasaur", count: 1 },
    { name: "Charmander", count: 10 },
    // { name: "Squirtle", count: 10 },
    { name: "Cow", count: 10 },
    // { name: "Groot", count: 10 },
    { name: "Panda", count: 10 },
    { name: "Pikachu", count: 10 },
    // { name: "No-face (fluffy)", count: 10 },
  ].sort(sortAlphabetical),
  price: 4000,
};

const medComplex = {
  category: "Med Complex",
  variations: [
    // { name: "Eevee", count: 1 },
    { name: "Gengar", count: 10 },
  ].sort(sortAlphabetical),
  price: 5000,
};

const complexHuman = {
  category: "Complex Human",
  variations: [
    { name: "Aang", count: 1 },
    { name: "Katara", count: 1 },
    { name: "Zuko", count: 1 },
    { name: "Sokka", count: 1 },
  ].sort(sortAlphabetical),
  price: 7500,
};

const bigFluffy = {
  category: "Big Fluffy",
  variations: [
    // { name: "Eevee", count: 1 },
    { name: "Totoro", count: 1 },
    // { name: "Pikachu", count: 1 },
    { name: "Jolteon", count: 1 },
  ].sort(sortAlphabetical),
  price: 9000,
};

const flowerCushion = {
  category: "Flower cushion",
  variations: [
    { name: "Pink", count: 1 },
    { name: "Yellow", count: 1 },
  ].sort(sortAlphabetical),
  price: 12000,
};

const haku = {
  category: "Haku",
  variations: [{ name: "Haku", count: 1 }],
  price: 20000,
}

const appa = {
  category: "Appa",
  variations: [{ name: "appa", count: 1 }],
  price: 25000,
};

const keychain = {
  category: "Keychain",
  variations: [
    { name: "Budgie (B)", count: 1 },
    { name: "Budgie (G)", count: 1 },
    { name: "Magpie", count: 1 },
    { name: "Kookaburra", count: 1 },
    { name: "Galah", count: 1 },
    { name: "Pigeon", count: 1 },
    { name: "Cockatiel", count: 1 },
    { name: "Cockatoo", count: 1 },
    { name: "Chicken", count: 1 },
    { name: "Duck", count: 1 },
    { name: "Raven", count: 1 },
    // { name: "Chick", count: 1 },
    { name: "Mushroom", count: 1 },
    // { name: "Luna", count: 1 },
    { name: "Carrot", count: 1 },
  ].sort(sortAlphabetical),
  price: 1500,
};

export const products: StockItem[] = [
  kits,
  starterKit,
  birds,
  cats,
  appa,
  flowerCushion,
  bigFluffy,
  // coasterKit,
  keychain,
  magnets,
  complexHuman,
  // medComplex,
  smallFluffy,
  // mediumDolls,
  pokemon,
  miniPokemon,
  clearDolls,
].sort((a, b) => {
  return a.price - b.price;
});
