import "./styles.scss";
import { useState, useEffect } from "react";
import { StockItem, StockItemVariation, products } from "./products";


interface Sale {
  items: Item[];
  time: Date;
  cash?: boolean;
}

interface Item {
  name: string;
  price: number;
}

const createItem = (item: StockItem, variation: StockItemVariation): Item => {
  return {
    name: `${item.category} - ${variation.name}`,
    price: item.price,
  };
};

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function StockList({ addItemToCart }: any) {
  return (
    <div className="products">
      {products?.map((product: any) => (
        <>
          <h2>
            {product.category}: ${product.price / 100}
          </h2>
          <div className="container">
            {product.variations.map((variation: any) => (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  addItemToCart(createItem(product, variation));
                }}
              >
                {variation.name}
              </a>
            ))}
          </div>
        </>
      ))}
    </div>
  );
}

function Row({ sale, onDelete }: { sale: Sale; onDelete: Function }) {
  return (
    <div className="row">
      <p>
        <ul className="items">
          {sale.items && sale.items.map((item) => <li>{item.name}</li>)}
        </ul>
      </p>
      <p>
        ${sale.items.reduce((sum, item) => sum + item.price, 0) / 100}
        <br />
        <strong>
          <small>{sale.cash && "CASH"}</small>
        </strong>
      </p>
      <p>
        {days[new Date(sale.time).getDay()]}{" "}
        {new Date(sale.time).toLocaleTimeString("en-AU")}
        &nbsp;
        <span
          onClick={(e) => {
            e.preventDefault();
            onDelete();
          }}
        >
          <small>Delete</small>
        </span>
      </p>
    </div>
  );
}

const supanova24StorageKey = "sales";
const collectorsXmasMarket24 = "collectorsMarket24";
const kaiconStorageKey = "kaigonSunday";
const salesStorageKey = kaiconStorageKey;

const eft = "eft";
const cash = "cash";

export default function App() {
  const [sales, setSales] = useState<Sale[]>(
    (JSON.parse(localStorage.getItem(salesStorageKey) as any) as Sale[]) || []
  );

  const yesterdaySales = JSON.parse(localStorage.getItem(collectorsXmasMarket24) as any) as Sale[];
  let yesterdayTotals: any = {};
  yesterdaySales.forEach((sale) => {
    sale.items.forEach((item) => {
      const cat = item.name.split(" - ")[0] || "";
      yesterdayTotals[`${cat}`] = parseInt(yesterdayTotals[`${cat}`] || 0) + 1;
    });
  });

  const [category, setCategory] = useState<StockItem>(products[0]);

  const [cart, setCart] = useState<Item[]>([]);
  const [totals, setTotals] = useState<any>([]);
  const [method, setMethod] = useState<string>(eft);

  useEffect(() => {
    localStorage.setItem(salesStorageKey, JSON.stringify(sales));

    let runningTotals: any = {};
    sales.forEach((sale) => {
      sale.items.forEach((item) => {
        const cat = item.name.split(" - ")[0] || "";
        runningTotals[`${cat}`] = parseInt(runningTotals[`${cat}`] || 0) + 1;
      });
    });

    console.log(runningTotals);

    setTotals(runningTotals);
  }, [sales]);

  const addItemToCart = (item: Item) => {
    setCart([...cart, item]);
  };

  const onDelete = (deleteSale: Sale) => {
    const filteredSales = sales.filter(
      (sale) => JSON.stringify(sale) !== JSON.stringify(deleteSale)
    );
    setSales(filteredSales);
  };

  const onCash = (cashSale: Sale) => {
    const cashSales = sales.map((sale) => {
      if (JSON.stringify(sale) === JSON.stringify(cashSale)) {
        sale.cash = !sale.cash;
      }
      return sale;
    });
    setSales(cashSales);
  };

  const removeFromCart = (deleteItem: any) => {
    const filteredCart = cart.filter(
      (item) => JSON.stringify(item) !== JSON.stringify(deleteItem)
    );
    setCart(filteredCart);
  };

  console.log("totals -> ", Object.entries(totals));

  return (
    <div className="App">
      <div className="pos">
        <div className="pos__menu">
          <ul>
            <li>POS</li>
            <li>Sales</li>
          </ul>
        </div>
        <div className="pos__container">
          <div className="pos__stock-list">
            <div className="pos__stock-list__category">
              {products?.map((product: StockItem) => (
                <a
                  className={product.category === category.category ? "active" : ""}
                  onClick={() => { setCategory(product) }}
                >
                  {product.category}: ${product.price / 100}
                </a>
              ))}
            </div>
            <div className="pos__stock-list__variations">
              <ul>
                {category.variations?.map((variation: StockItemVariation) => (
                    <li
                        className="card"
                        onClick={(e) => {
                          e.preventDefault();
                          addItemToCart(createItem(category, variation));
                        }}
                    >
                      <p className="card__price">${category.price / 100}</p>
                      <p className="card__title">{variation.name}</p>
                    </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="pos__cart">
            <h3>Items</h3>
            <div className="pos__cart__items">
              <ul>
                {cart?.map((item, key) => (
                  //TODO: Remove one item when there are two in the cart (currently removes both)
                  <li key={`${JSON.stringify(item)}-${key}`}>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        removeFromCart(item);
                      }}
                    >
                      x
                    </a>
                    <span>{item.name}</span>
                    <span>${item.price / 100}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="pos__cart__totals">
              <p>
                <strong>Total</strong> $
                {cart.reduce((sum, item) => sum + item.price, 0) / 100}
              </p>
            </div>

            <div className="pos__cart__buttons">
              <div className="pos__cart__buttons__payment">
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setMethod(eft);
                  }}
                  className={method === eft ? "selected" : ""}
                >
                  Eftpos
                </a>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setMethod(cash);
                  }}
                  className={method === cash ? "selected" : ""}
                >
                  Cash
                </a>
              </div>
              <a
                className="button"
                onClick={(e) => {
                  e.preventDefault();
                  setSales([
                    ...sales,
                    {
                      items: cart,
                      time: new Date(),
                      cash: method === cash,
                    },
                  ]);
                  setCart([]);
                }}
              >
                Add sale
              </a>
              <a
                className="button button--secondary"
                onClick={(e) => {
                  e.preventDefault();
                  setCart([]);
                }}
              >
                Clear cart
              </a>
            </div>
          </div>
        </div>
      </div>

      <h2>Sales</h2>
      <div className="sales">
        <div className="row">
          <p>
            <strong>Items</strong>
          </p>
          <p>
            <strong>
              Total: $
              {sales.reduce(
                (total, sale) =>
                  total + sale.items.reduce((sum, item) => sum + item.price, 0),
                0
              ) / 100}
            </strong>
          </p>
          <p>
            <strong>Time</strong>
          </p>
        </div>
        {sales.length > 0 &&
          sales.map((sale) => (
            <Row onDelete={() => onDelete(sale)} sale={sale} />
          ))}
      </div>

      <p>Yesterday</p>

      <div className="sales">
        <div className="row">
          <p>
            <strong>Items</strong>
          </p>
          <p>
            <strong>
              Total: $
              {yesterdaySales.reduce(
                  (total, sale) =>
                      total + sale.items.reduce((sum, item) => sum + item.price, 0),
                  0
              ) / 100}
            </strong>
          </p>
          <p>
            <strong>Time</strong>
          </p>
        </div>
        {yesterdaySales.length > 0 &&
        yesterdaySales.map((sale) => (
            <Row onDelete={() => onDelete(sale)} sale={sale} />
        ))}
      </div>

      <h2>Totals</h2>

      <ul style={{ textAlign: "left" }}>
        {totals &&
          Object.entries(totals).map((value: any) => (
            <li>
              <>
                {value[0]}: {value[1]}
              </>
            </li>
          ))}
      </ul>

      <p>Yesterday</p>

      <ul style={{ textAlign: "left" }}>
        {yesterdayTotals &&
        Object.entries(yesterdayTotals).map((value: any) => (
            <li>
              <>
                {value[0]}: {value[1]}
              </>
            </li>
        ))}
      </ul>
    </div>
  );
}
